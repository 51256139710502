import React from 'react'
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql, Link } from "gatsby"
import '../styles/Hero'
import '../styles/TwoCols'

import TEAM from '../images/about/team.svg'
import MISSION from '../images/about/mission.svg'
import PARTNERS from '../images/about/partners.svg'



export default function AboutPage() {
  const root = useClasses()
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/about/hero.jpg" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout full dark>
      <SEO title='About' image={heroImage.childImageSharp.fluid.src}/>
      <main className='main' ref={root}>

        <BackgroundImage className='hero-container' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className='hero-inner'>
            <div className='hero-text'>
              <div className='hero-main'>Welcome to ...</div>
              <div className='hero-slanted'>Beautiful Montreal</div>
            </div>
          </div>
        </BackgroundImage>

        <div className='container'>
          <div className="heading-separator"/>
          <h1 className="title">About US</h1>
          <h2 className="subtitle">A truly international team</h2>
          <div className="description">
            <p>
              From humble beginnings in a suburb of Montreal, we are now a truly international team of mission-driven engineers and professionals. 
            </p>
            <p>
              Over the last 15 years we have deployed unique and powerful technologies in 5 continents (Antartica researach stations aren't answering our calls).
              Starting in the education sector (we are the creators of the award-winning LMS ClassroomAPP) we now serve all major verticals.
              We'd love to <Link to="/contact">hear from you</Link> to chat about anything fun. Don't be a stranger.
            </p>
            <p>
              We support many worthwhile causes, our main focus has traditionally been helping kids and youth.
              If you have ideas on how we can help somewhere, whether with free licenses, coaching or support, we'd absolutely love to hear from you.
            </p>
          </div>

          <div className="twocols">
            <div className="twocols-col-image">
              <img src={MISSION}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">Mission</div>
              <div className="heading">Key member in the revolution</div>
              <div className="description">
                <p>The technology landscape looks nothing like it was when the first IBM PC came out.</p>
                <p>Yet the same thinking is driving much of companies' technology acquisition, with the same key players offering underperforming solutions.</p>
                <p className="quote">We believe we can simultaneously support the development community, and the performing teams that want to move forward. We will do this by leveraging the latest in technology, tools & processes to provide more optimal cost-effective solutions.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={TEAM}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">Expertise</div>
              <div className="heading">Excellence in Technology</div>
              <div className="description">
                <p>Our engineers are top-notch. Really.</p>
                <p>We develop & support some really solid stacks, like OneOffice, ERP, ClassroomAPP and the upcoming Project Management Tool.</p>
                <p className='quote'>We have developed some of the world's smarted deployment & update automation tools to have predictable and solid performance on hundreds of servers. All the time.</p>
                <p>Our team is technical-heavy, and we leverage global partners in key geographies to support us in delivering to the end-customer.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={PARTNERS}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">Partners</div>
              <div className="heading">Two words ... Thank you!</div>
              <div className="description">
                <p>We are honored and blessed to work with some of the world's most pre-eminent organizations.</p>
                <p>From Huawei, Acer to Fujitsu, Random House & Etisalat. We have been part of some really exciting projects that have made a real difference.</p>
                <p>Thank you to all our partners & friends for their continued trust.</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
